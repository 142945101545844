import { isMobile, isTablet } from '../util/is_mobile'

up.compiler('[message-new-form]', (element, data) => {
  const maxFileSize = data.max_file_size
  const humanizedFileSize = data.humanized_file_size
  const extensionAllowlist = data.extension_allowlist

  let textarea = element.querySelector('textarea[name*="text"]')
  let attachmentField = element.querySelector('[type="file"]')
  let submitButton = element.querySelector('[type="submit"]')
  let form = element.querySelector('form')
  let fileFormGroup = element.querySelector('.form-group.file')
  let fileFormControl = element.querySelector('.input-group')

  function toggleSubmitButton() {
    submitButton.disabled = (textareaEmpty() && attachmentFieldEmpty()) || invalidFileSize() || invalidFileExtension()
  }

  function submitOnCtrlEnterPressed(event) {
    let enterPressed = (event.key === 'Enter')
    if (enterPressed && event.ctrlKey && (!textareaEmpty() || !attachmentFieldEmpty()) && !isMobileOrTablet()) {
      up.submit(form)
      event.preventDefault() // Prevents the addition of a new line in the text field
    }
  }

  function textareaEmpty() {
    return up.util.isBlank(trimmedInputValue())
  }

  function trimmedInputValue() {
    return textarea.value.trim()
  }

  function attachmentFieldEmpty() {
    return up.util.isBlank(attachmentField.value)
  }

  function isMobileOrTablet() {
    return isMobile() || isTablet()
  }

  function toggleFileSizeError() {
    if (invalidFileSize()) {
      fileFormGroup.querySelector('.invalid-feedback.-size')?.remove()
      let errorFeedback = up.element.createFromHTML(
      `<div class="invalid-feedback -size">
        File size is too big. Max file size is ${humanizedFileSize}.
      </div>`
      )
      fileFormGroup.append(errorFeedback)
    } else {
      fileFormGroup.querySelector('.invalid-feedback.-size')?.remove()
    }
  }

  function invalidFileSize() {
    let file = attachmentField.files.item(0)
    return !!(file && file.size > maxFileSize)
  }

  function toggleExtensionError() {
    if (invalidFileExtension()) {
      fileFormGroup.querySelector('.invalid-feedback.-extension')?.remove()
      let errorFeedback = up.element.createFromHTML(
      `<div class="invalid-feedback -extension">
        File extension is not allowed.
      </div>`
      )
      fileFormGroup.append(errorFeedback)
    } else {
      fileFormGroup.querySelector('.invalid-feedback.-extension')?.remove()
    }
  }

  function invalidFileExtension() {
    let file = attachmentField.files.item(0)
    if (!file) { // no file is uploaded
      return false;
    }

    // Extract the file extension from the file name
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return !extensionAllowlist.includes(fileExtension);
  }

  function toggleErrors() {
    if (!invalidFileSize() && !invalidFileExtension()) {
      fileFormGroup.classList.remove('form-group-invalid')
      fileFormControl.classList.remove('is-invalid')
    } else {
      fileFormGroup.classList.add('form-group-invalid')
      fileFormControl.classList.add('is-invalid')
    }

    toggleFileSizeError()
    toggleExtensionError()
  }

  function handleAttachmentFieldChange() {
    toggleErrors()
    toggleSubmitButton()
  }

  textarea.addEventListener('input', toggleSubmitButton)
  textarea.addEventListener('keypress', submitOnCtrlEnterPressed)
  attachmentField.addEventListener('change', handleAttachmentFieldChange)

  toggleSubmitButton()
})
