import { settings } from '../settings.js'

class LinkChecker {
  constructor() {}

  targetIsUrl(target) {
    const isModernUrlTarget = (target.origin !== null) && (target.origin !== '') && (target.origin !== 'null')
    const isIEUrlTarget = (target.host !== null) && (target.host !== '') && (target.host !== 'null')
    return isModernUrlTarget || isIEUrlTarget
  }

  isNoApplicationUrl(target) {
    if (!this.targetIsUrl(target)) { return false }
    const urlHostName = target.hostname
    const isNoApplication = !this.isMailAddress(target) && !this.isApplicationHost(urlHostName)
    return isNoApplication
  }

  isExternalUrl(target) {
    if (!this.targetIsUrl(target)) { return false }
    const urlHostName = target.hostname
    const isExternal = !this.isMailAddress(target) && !this.isKnownHost(urlHostName)
    return isExternal
  }

  isMailAddress(target) {
    return (target.origin === 'mailto://') || (target.protocol === 'mailto:')
  }

  applicationDomains() {
    return settings.get('applicationDomains')
  }

  isApplicationHost(urlHostName) {
    return !!this.applicationDomainListRegex().exec(urlHostName)
  }

  applicationDomainListRegex() {
    const anySubdomainUrlMatcher = this.applicationDomains().map(name => `((.+)\.)?${name}`)
    return new RegExp(anySubdomainUrlMatcher.join('|'))
  }

  knownDomains() {
    return settings.get('customerDomains').concat(this.applicationDomains())
  }

  isKnownHost(urlHostName) {
    return !!this.knownDomainListRegex().exec(urlHostName)
  }

  knownDomainListRegex() {
    const anySubdomainUrlMatcher = this.knownDomains().map(name => `((.+)\.)?${name}$`)
    return new RegExp(anySubdomainUrlMatcher.join('|'))
  }
}

export const linkChecker = new LinkChecker()
